import { useNavigate, useParams } from "react-router-dom"

export const useNavigateSubTab = (): [
  string | undefined,
  (ident: string) => void,
] => {
  const navigate = useNavigate()
  const { activeTab, activeSubTab } = useParams()
  return [activeSubTab, (ident: string) => navigate(`/${activeTab}/${ident}`)]
}
