import JSZip from "jszip"
import readXlsxFile from "read-excel-file"
import BaseApi from "../../api/api"
import { windowLock } from "../../utils/loader"

export const getWbkatrnLogData = () => {
  return BaseApi.logForKatrenWb().then((res) => {
    if (res.success) return res.data
    return []
  })
}

export const getOzonKatrnLogData = () => {
  return BaseApi.logForKatrenOzon().then((res) => {
    if (res.success) return res.data
    return []
  })
}

export const prepareXLSX = async (
  files: { [key: string]: JSZip.JSZipObject },
  mainFileName: string,
) => {
  windowLock()
  const result: {
    [key: string]: { name: string; amount: number; price: number }
  } = {}
  for (const key in files) {
    const file = files[key]
    // Проверка, что файл является обычным файлом (а не подкаталогом)
    if (!file.dir && key.split(".").pop() === "xlsx") {
      const data = await file.async("arraybuffer")
      const dataFromXslx = await readXlsxFile<any>(data, {
        schema: schemaDataFromKatren,
      })
      if (dataFromXslx.rows) {
        dataFromXslx.rows.forEach((el) => {
          const { name, amount, price } = el
          if (result[name]) {
            if (key === mainFileName) result[name].amount = amount
            result[name].price =
              result[name].price > price ? result[name].price : price
          } else {
            if (key === mainFileName) {
              result[name] = { name, amount, price }
            } else result[name] = { name, amount: 0, price }
          }
        })
      }
    }
  }

  return Object.values(result)
}

export const prepareZip = async (file: File) => {
  const zip = new JSZip()
  const buffer = await file.arrayBuffer()
  await zip.loadAsync(buffer)
  const files = zip.files
  return files
}
export const schemaDataFromKatren = {
  Название: {
    prop: "name",
    type: String,
  },
  Остаток: {
    prop: "amount",
    type: Number,
  },
  "Цена за 1 уп.": {
    prop: "price",
    type: Number,
  },
}
