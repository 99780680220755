import { STATUS } from "../utils/const"

const msgs = {
  [STATUS.ERROR]: "Ошибка",
  [STATUS.SUCCESS]: "Успешно",
  [STATUS.IN_PROGRESS]: "В процессе",
  [STATUS.INVALID_COOKIES]: "Не валидные куки",
  [STATUS.INVALID_FILES]: "Не валидные файлы",
  [STATUS.INVALID_WAREHOUSE]: "Не валидный склад",
}

export const getMessage = (ident: string): string => {
  return msgs[ident as keyof typeof msgs] || ident
}
