type IMessage = { type: string; data?: { key?: ActionKey } & unknown }

export type ActionKey =
  | "uploadState"
  | "updateStoksState"
  | "ordersStore"
  | "iconUpdateStoksState"
  | "katrenUpdateStoksState"
  | "podrUpdateStoksState"
  | "metroUpdateStoksState"
  | "settings"
  | "podruzhkaBasket"

export class WSocketService {
  private static socket: WebSocket | null = null
  static isConnected: boolean | null = null
  private static messages: any[] = []
  private static listeners: Map<
    string,
    ((data: IMessage["data"]) => void)[]
  > = new Map()

  static connect(url: string) {
    if (this.isConnected) return

    const socket = new WebSocket(url)
    this.socket = socket
    socket.onopen = () => {
      this.isConnected = true
    }

    socket.onmessage = (event: MessageEvent<string>) => {
      try {
        console.log(event.data)
        const message: IMessage = JSON.parse(event.data)
        const { data, type } = message
        const listeners = this.listeners.get(type) || []
        listeners.forEach((listener) => listener(data))
        this.messages.push(event.data)
      } catch (e) {
        console.error(e)
      }
    }

    socket.onclose = () => {
      this.isConnected = false
    }
  }

  static subscribe(type: string, listener: (data: IMessage["data"]) => void) {
    const listeners = this.listeners.get(type) || []
    listeners.push(listener)
    this.listeners.set(type, listeners)
  }

  static disconnect() {
    if (this.socket) {
      this.socket.close()
      this.listeners.clear()
      this.socket = null
      this.isConnected = false
    }
  }
}
