import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { SCHEMA_PODR } from "../../components/UpdateStoksPodrugka/const"
import readXlsxFile from "read-excel-file"
import BaseApi from "../../api/api"
import { windowLock } from "../../utils/loader"
import { NotificationService } from "../../features/NotificationService/NotificationService"
import { STATUS } from "../../utils/const"

type initialStateType = PodrStateType & {
  orders?: PodrOrdersType
}

export type PodrOrdersType = {
  [key: string]: {
    items: {
      id: string
      name: string
      brand: string
      quantity: number
      category: string
      price: string
      discount: number
    }[]
    value: number
  }
}

type PodrStateType = {
  wildberries: {
    lastStoksUpdated: string
    lastPricesUpdated: string
    status: STATUS
    isCurrentDataUploaded: boolean
  }
}

const initialState: initialStateType = {
  wildberries: {
    lastStoksUpdated: "",
    lastPricesUpdated: "",
    status: STATUS.SUCCESS,
    isCurrentDataUploaded: false,
  },
}

const updateStoksPodruzhkaSlice = createSlice({
  name: "updateStoksPodruzhka",
  initialState,
  reducers: {
    setUpdateStoksPodruzhkaState: (
      state,
      action: PayloadAction<PodrStateType>,
    ) => {
      state.wildberries = action.payload.wildberries
    },
    setPodruzhkaOrders: (state, action: PayloadAction<PodrOrdersType>) => {
      state.orders = action.payload
    },
  },
})

export const selectUpdateStoksPodruzhkaStore = (state: RootState) =>
  state.updateStoksPodruzhka

export default updateStoksPodruzhkaSlice.reducer

export const {
  setUpdateStoksPodruzhkaState,
  setPodruzhkaOrders,
} = updateStoksPodruzhkaSlice.actions

export const uploadCurrentDataPodruzhka = createAsyncThunk(
  "uploadCurrentDataPodruzhka",
  async (file: File, { dispatch }) => {
    windowLock()

    try {
      const dataFromXslx = await readXlsxFile<{
        Id: string
        GoodCode: string
        sku: string
        count: number
        nmId: number
        productId: string
      }>(file, {
        schema: SCHEMA_PODR,
      })

      const jsonData = dataFromXslx.rows

      BaseApi.uploadCurrentDataPodruzhka(jsonData)
        .then((response) => {
          response.data?.state &&
            dispatch(setUpdateStoksPodruzhkaState(response.data.state))
          NotificationService.showNotification({
            message: response.data.message,
            level: (response.data.success && "success") || "error",
          })
        })
        .catch((error) => {
          console.error(error)
          error?.response?.data?.state &&
            dispatch(setUpdateStoksPodruzhkaState(error.response.data.state))
        })
    } catch (error) {
      console.error("Error reading file:", error)
      NotificationService.showNotification({
        message: "Ошибка при чтении файла",
        level: "error",
      })
    }
  },
)

export const deleteCurrentDataPodruzhka = createAsyncThunk(
  "deleteCurrentDataPodruzhka",
  async (_, { dispatch }) => {
    BaseApi.deleteCurrentDataPodruzhka()
      .then((response) => {
        response.data?.state &&
          dispatch(setUpdateStoksPodruzhkaState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksPodruzhkaState(error.response.data.state))
      })
  },
)

export const updateStoksPodruzhkaState = createAsyncThunk(
  "updateStoksPodruzhkaState",
  (_, { dispatch }) => {
    BaseApi.podruzhkaUpdateStoksState()
      .then((response) => {
        if (response.data) {
          dispatch(setUpdateStoksPodruzhkaState(response.data))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  },
)

export const podruzhkaUpdateStoks = createAsyncThunk(
  "podruzhkaUpdateStoks",
  (
    props: {
      selectedStorageId: string
      updatePrices: boolean
      updateStocks: boolean
    },
    { dispatch },
  ) => {
    const { selectedStorageId, updatePrices, updateStocks } = props
    BaseApi.podruzhkaUpdateStoks(selectedStorageId, updatePrices, updateStocks)
      .then((response) => {
        if (response.data) {
          response.data?.state &&
            dispatch(setUpdateStoksPodruzhkaState(response.data.state))
          NotificationService.showNotification({
            message: response.data.message,
            level: (response.data.success && "success") || "error",
          })
        }
      })
      .catch((error) => {
        error?.response?.data?.state &&
          dispatch(setUpdateStoksPodruzhkaState(error.response.data.state))
      })
  },
)

export const getPodrOrders = createAsyncThunk<
  Promise<void>,
  void,
  { state: RootState }
>("updateStoksPodruzhka/getPodrOrders", async (_, { dispatch, getState }) => {
  const state = getState()
  const orders = state.updateStoksPodruzhka.orders
  if (orders) {
    return
  }
  const response = await BaseApi.podruzhkaOrders()
  dispatch(setPodruzhkaOrders(response.data?.data))
})
