import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import BaseApi from "../../api/api"

type infoDataType = {
  vendorCode: string
  amountDistr: string | number
  nameDistr: string
  priceDistr: string | number
}
type initialStateType = {
  infoData: infoDataType[]
}

const initialState: initialStateType = {
  infoData: [],
}
const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setInfoDataByVendorCode: (state, action: PayloadAction<infoDataType[]>) => {
      state.infoData = action.payload
    },
  },
})

export const serchByVendoreCode = createAsyncThunk(
  "serchByVendoreCode",
  (vendorCodesStr: string, { dispatch }) => {
    const vendorCodes = vendorCodesStr.split(",").map((el) => el.trim())
    BaseApi.serachByVendorCode(vendorCodes).then((data: any) => {
      dispatch(
        setInfoDataByVendorCode(
          data.map((el: any) => ({
            vendorCode: el.vendorCode,
            amountDistr: el.amountDistr,
            nameDistr: el.nameDistr,
            priceDistr: el.priceDistr,
          })),
        ),
      )
    })
  },
)

export const selectInfo = (state: RootState) => state.info.infoData

export default infoSlice.reducer

export const { setInfoDataByVendorCode } = infoSlice.actions
