import { PriceSetting } from "./PriceSetting"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  selectSettings,
  setPriceParams,
  PriceType,
  selectActiveShops,
  updatePricesParams,
} from "../../app/reducers/settingsReducer"
import s from "./Settings.module.css"
import { Tabs } from "../Tabs/Tabs"
import { TabContent } from "../Tab/TabContent"
import { useNavigateSubTab } from "../../utils/hooks/useNavigateTab"

export const Settings = () => {
  const dispatch = useAppDispatch()
  const settingspriceState = useAppSelector(selectSettings)
  const activeShops = useAppSelector(selectActiveShops)
  const [activeSubTab, navigate] = useNavigateSubTab()
  return (
    <div className={s.settings}>
      <Tabs onChange={navigate} activeTabIdent={activeSubTab}>
        {activeShops.map((shopName) => {
          const {
            margin,
            profit,
            logistic,
            ff,
            coef,
            tax,
            sale,
            priemka,
            ekv,
            mila,
          } = settingspriceState[shopName]?.price || {}
          return (
            <TabContent
              key={`tabcontent-${shopName}`}
              name={`Калькулятор для ${shopName}`}
              ident={shopName}
            >
              <PriceSetting
                onSave={(args: PriceType) =>
                  dispatch(updatePricesParams({ shop: shopName, price: args }))
                }
                margin={margin}
                profit={profit}
                logistic={logistic}
                ff={ff}
                coef={coef}
                tax={tax}
                sale={sale}
                priemka={priemka}
                ekv={ekv}
                mila={mila}
                shop={shopName}
                onChange={(args: PriceType) =>
                  dispatch(
                    setPriceParams({ shop: shopName, price: args as any }),
                  )
                }
              />
            </TabContent>
          )
        })}
      </Tabs>
    </div>
  )
}
