import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import BaseApi from "../../api/api"
import { NotificationService } from "../../features/NotificationService/NotificationService"

export type IOrder = {
  address: string | null
  deliveryType: string
  user: any
  orderUid: string
  article: string
  rid: string
  createdAt: string
  offices: string[]
  skus: string[]
  id: number
  warehouseId: number
  nmId: number
  chrtId: number
  price: number
  convertedPrice: number
  currencyCode: number
  convertedCurrencyCode: number
  cargoType: number
}

export type OzonProductType = {
  price: string
  offer_id: string
  name: string
  sku: number
  quantity: number
  mandatory_mark: any[]
  currency_code: string
}

export type IOzonOrder = {
  postingNumber: string
  orderId: number
  orderNumber: string
  status: string
  products: OzonProductType[]
  warehouseId: number
}

export type ISupply = {
  id: string
  done: boolean
  createdAt: string
  closedAt: string
  scanDt: string
  name: string
  cargoType: number
  orders?: IOrder[]
}

type initialStateType = {
  orders: IOrder[]
  ozonOrders: IOzonOrder[]
  supplies: ISupply[]
  lastDateUpdatedSupplies?: string
  storages: {
    name: string
    officeId: number
    id: number
  }[]
  ozonStorages: {
    name: string
    warehouse_id: number
  }[]
  formOrders?: {
    GoodName: string
    GoodCode: string
    Quantity: number
  }[]
}
const initialState: initialStateType = {
  orders: [],
  ozonOrders: [],
  supplies: [],
  lastDateUpdatedSupplies: undefined,
  storages: [],
  ozonStorages: [],
}
const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<IOrder[]>) => {
      state.orders = action.payload
    },
    setOzonOrders: (state, action: PayloadAction<IOzonOrder[]>) => {
      state.ozonOrders = action.payload
    },
    setOrderState(state, actions: PayloadAction<initialStateType>) {
      state.orders = actions.payload.orders
      state.supplies = actions.payload.supplies
      state.storages = actions.payload.storages
      state.ozonStorages = actions.payload.ozonStorages
      actions.payload.lastDateUpdatedSupplies &&
        (state.lastDateUpdatedSupplies = new Date(
          actions.payload.lastDateUpdatedSupplies,
        ).toLocaleString())
    },
    setSupplies(
      state,
      action: PayloadAction<{
        supplies: ISupply[]
        lastDateUpdatedSupplies?: string
      }>,
    ) {
      state.supplies = action.payload.supplies
      action.payload.lastDateUpdatedSupplies &&
        (state.lastDateUpdatedSupplies = action.payload.lastDateUpdatedSupplies)
    },
    setFormOrders(
      state,
      action: PayloadAction<initialStateType["formOrders"]>,
    ) {
      state.formOrders = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setNewOrders.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.orders = action.payload?.orders || []
        }
        NotificationService.showNotification({
          message: action.payload.message,
          level: (action.payload.success && "success") || "error",
        })
      })
      .addCase(setNewOrders.rejected, (state, action) => {
        state.orders = []
        NotificationService.showNotification({
          message: "ошибка",
          level: "error",
          errorBody: action.error.message,
        })
      })
  },
})

export const selectOrdersStore = (state: RootState) => state.ordersStore

export const {
  setOrders,
  setOrderState,
  setSupplies,
  setFormOrders,
  setOzonOrders,
} = orderSlice.actions

export default orderSlice.reducer

export const setNewOrders = createAsyncThunk(
  "orders/setNewOrders",
  async () => {
    const response = await BaseApi.getNewOrders()
    return response.data
  },
)

export const setOzonNewOrders = createAsyncThunk(
  "orders/setOzonNewOrders",
  async (_, { dispatch }) => {
    const response = await BaseApi.getOzonNewOrders()
    dispatch(setOzonOrders(response.data.orders))
    NotificationService.showNotification({
      message: response.data.message,
      level: (response.data.success && "success") || "error",
    })
  },
)

export const getOrdersState = createAsyncThunk(
  "orders/getOrdersState",
  (_, { dispatch }) => {
    BaseApi.getOrdersState()
      .then((response) => {
        dispatch(setOrderState(response?.data))
      })
      .catch((error) => {
        dispatch(
          setOrderState({
            orders: [],
            ozonOrders: [],
            supplies: [],
            lastDateUpdatedSupplies: undefined,
            storages: [],
            ozonStorages: [],
          }),
        )
        console.error(error)
      })
  },
)

export const updateSupplies = createAsyncThunk(
  "orders/updateSupplies",
  async (_, { dispatch }) => {
    BaseApi.updateSupplies()
      .then((response) => {
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
        dispatch(
          setSupplies({
            supplies: response?.data.supplies,
            lastDateUpdatedSupplies:
              (response?.data.lastDateUpdatedSupplies &&
                new Date(
                  response?.data.lastDateUpdatedSupplies,
                ).toLocaleString()) ||
              response?.data.lastDateUpdatedSupplies,
          }),
        )
      })
      .catch((error) => {
        NotificationService.showNotification({
          message: error?.message,
          level: "error",
          errorBody: error,
        })
      })
  },
)

export const addOrdersToSupplies = createAsyncThunk(
  "addOrdersToSupplies",
  (props: { supplyId: number; orderIds: number[] }, { dispatch }) => {
    const { supplyId, orderIds } = props
    BaseApi.addOrdersToSupplies(supplyId, orderIds)
      .then((response) => {
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
        dispatch(setNewOrders())
      })
      .catch((error) => {
        NotificationService.showNotification({
          message: error?.message,
          level: "error",
          errorBody: error,
        })
      })
  },
)

export const createNewSuppliesAndAddOrders = createAsyncThunk(
  "createNewSuppliesAndAddOrders",
  (props: { name: string; orderIds: number[] }, { dispatch }) => {
    const { name, orderIds } = props
    BaseApi.createNewSuppliesAndAddOrders(name, orderIds)
      .then((response) => {
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
        dispatch(setNewOrders())
        dispatch(updateSupplies())
      })
      .catch((error) => {
        NotificationService.showNotification({
          message: error?.message,
          level: "error",
          errorBody: error,
        })
      })
  },
)

export const getSuppliesWBWithOrders = createAsyncThunk(
  "getSuppliesWBWithOrders",
  async (supplyId: string, { dispatch }) => {
    try {
      const response = await BaseApi.getSuppliesWithOrders(supplyId)
      dispatch(
        setSupplies({
          supplies: response?.data.supplies,
          lastDateUpdatedSupplies:
            (response?.data.lastDateUpdatedSupplies &&
              new Date(
                response?.data.lastDateUpdatedSupplies,
              ).toLocaleString()) ||
            response?.data.lastDateUpdatedSupplies,
        }),
      )
    } catch (e: any) {
      NotificationService.showNotification({
        message: e?.message,
        level: "error",
        errorBody: e,
      })
    }
  },
)
