import { FC, useEffect, useState } from "react"
import s from "./NewOrders.module.css"
import {
  IOrder,
  addOrdersToSupplies,
  createNewSuppliesAndAddOrders,
  getOrdersState,
  selectOrdersStore,
  setNewOrders,
  updateSupplies,
} from "../../app/reducers/ordersReducer"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Modal } from "../../features/Modal/Modal"
import { CreateNewSupplies } from "./wb/CreateNewSupplies"
import { AddToSupplies } from "./wb/AddToSupplies"
import { Tab } from "../Tab/Tab"
import { Tabs } from "../Tabs/Tabs"
import { useNavigateSubTab } from "../../utils/hooks/useNavigateTab"
import { TabContent } from "../Tab/TabContent"
import { WBNewOrders } from "./wb/WBNewOrders"
import { OzonNewOrders } from "./ozon/OzonNewOrders"
import {selectActiveShops} from "../../app/reducers/settingsReducer"

export const NewOrders: FC = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getOrdersState())
  }, [])
  const ordersStore = useAppSelector(selectOrdersStore)
  const {
    orders,
    storages,
    supplies,
    lastDateUpdatedSupplies,
    ozonStorages,
    ozonOrders,
  } = ordersStore
  const [activeSubTab, navigate] = useNavigateSubTab()
  const activeShops = useAppSelector(selectActiveShops)
  return (
    <Tabs onChange={navigate} activeTabIdent={activeSubTab}>
      <TabContent name="Wildberries" ident={"wildberries"}>
        <WBNewOrders
          orders={orders}
          storages={storages}
          supplies={supplies}
          lastDateUpdatedSupplies={lastDateUpdatedSupplies}
        />
      </TabContent>
      {activeShops.includes("KATREN_OZON") && <TabContent name="OZON" ident={"ozon"}>
        <OzonNewOrders orders={ozonOrders} storages={ozonStorages} />
      </TabContent>}
    </Tabs>
  )
}
