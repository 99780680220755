import { useEffect, useState } from "react"
import { Tab } from "../Tab/Tab"
import s from "./tabs.module.css"

type tabsProps = {
  children: (any | undefined)[]
  activeTabIdent?: string
  onChange?: (ident: string) => void
}
export const Tabs = ({
  children,
  activeTabIdent: activeIdent,
  onChange,
}: tabsProps) => {
  const [activeTabIdent, setActiveTabIdent] = useState(children[0]?.props.ident)
  useEffect(() => {
    const tab = children.find((tab) => tab?.props?.ident === activeIdent)
    if (tab && tab.props.ident !== activeTabIdent) {
      setActiveTabIdent(tab.props.ident)
    } else if (!tab) {
      const firstTabIdent = children[0]?.props.ident
      setActiveTabIdent(firstTabIdent)
    }
  }, [activeIdent, children])

  return (
    <div className={s.tabs}>
      <div className={s.tabsHeader}>
        {children.map((TabEl, i) => {
          if (!TabEl) return null
          return (
            <div
              className={`${s.tabsHeader__el} ${
                activeTabIdent === TabEl.props.ident ? s.active : ""
              }`}
              key={"tabBtn" + TabEl.props.name + i}
              onClick={() => {
                setActiveTabIdent(TabEl.props.ident)
                onChange && onChange(TabEl.props.ident)
              }}
            >
              {TabEl.props.name}
            </div>
          )
        })}
      </div>
      <div>
        {children.map((TabEl, i) => {
          if (!TabEl) return null
          return (
            <Tab
              key={"tabcontent" + TabEl.props.name + i}
              active={activeTabIdent === TabEl.props.ident}
            >
              {TabEl}
            </Tab>
          )
        })}
      </div>
    </div>
  )
}
