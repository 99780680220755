import { FC } from "react"
import { IOrder } from "../../../app/reducers/ordersReducer"

type CreateNewSuppliesProps = {
  orders: IOrder[]
  newSupplyName: string
}
export const CreateNewSupplies: FC<CreateNewSuppliesProps> = ({
  orders,
  newSupplyName,
}) => {
  return (
    <div>
      <h5>Выбранные позиции</h5>
      <div>
        <label htmlFor="newSupplyName"></label>
        <input type="text" id="newSupplyName" value={newSupplyName} disabled />
        <table>
          <thead>
            <tr>
              <th>№ задания</th>
              <th>Создано</th>
              <th>Склад</th>
              <th>Артикул продавца</th>
              <th>Артикул WB</th>
              <th>Цена</th>
            </tr>
          </thead>
          <tbody>
            {orders?.map((el, i) => {
              return (
                <tr key={el.article + "-createNewSupp-" + i}>
                  <td>{el.id}</td>
                  <td>{new Date(el.createdAt).toLocaleString()}</td>
                  <td>{el.warehouseId}</td>
                  <td>{el.article}</td>
                  <td>{el.nmId}</td>
                  <td>{el.convertedPrice / 100}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
