export const SCHEMA_PODR = {
  Id: {
    prop: "Id",
    type: String,
    required: true,
  },
  GoodCode: {
    prop: "GoodCode",
    type: String,
    required: true,
  },
  sku: {
    prop: "sku",
    type: String,
    required: true,
  },
  count: {
    prop: "count",
    type: Number,
    required: true,
  },
  nmId: {
    prop: "nmId",
    type: Number,
    required: true,
  },
  productId: {
    prop: "productId",
    type: String,
    required: false,
  },
}
