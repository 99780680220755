import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import BaseApi from "../../api/api"
import { NotificationService } from "../../features/NotificationService/NotificationService"

export type PriceType = {
  margin?: number
  profit?: number
  logistic?: number
  ff?: number
  coef?: number
  tax?: number
  sale?: number
  priemka?: number
  ekv?: number
  mila?: number
}

type ShopSettingsType = {
  price: PriceType
}

type ActiveShopsType<
  T = "ICON" | "KATREN" | "KATREN_OZON" | "PODRUZHKA" | "METRO"
> = T[]

type initialStateType = {
  [key in
    | "ICON"
    | "KATREN"
    | "KATREN_OZON"
    | "PODRUZHKA"
    | "METRO"]?: ShopSettingsType
} & {
  activeShops: ActiveShopsType
}

const initialState: initialStateType = {
  activeShops: [],
}

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setPriceParams: (
      state,
      action: PayloadAction<{
        shop: "ICON" | "KATREN" | "KATREN_OZON" | "PODRUZHKA" | "METRO"
        price: PriceType
      }>,
    ) => {
      state[action.payload.shop] = {
        ...state[action.payload.shop],
        price: action.payload.price,
      }
    },
    setSettingsState: (state, action: PayloadAction<initialStateType>) => {
      Object.keys(action.payload).forEach((key) => {
        // @ts-expect-error
        state[key] = action.payload[key as keyof initialStateType]
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSettingsState.fulfilled, (state, action) => {
        Object.keys(action.payload).forEach((key) => {
          // @ts-expect-error
          state[key] = action.payload[key as keyof initialStateType]
        })
      })
      .addCase(updateSettingsState.rejected, (state, action) => {
        console.error(action.error)
      })
      .addCase(updatePricesParams.fulfilled, (state, action) => {
        NotificationService.showNotification({
          message: action.payload.message,
          level: (action.payload.success && "success") || "error",
        })
      })
  },
})

export const updateSettingsState = createAsyncThunk(
  "settings/load",
  async () => {
    const response = await BaseApi.settings()
    return response.data
  },
)

export const updatePricesParams = createAsyncThunk(
  "settings/priceparam/update",
  async (params: { shop: string; price: PriceType }, { dispatch }) => {
    return BaseApi.updatePriceParams(params.shop, params.price).then(
      (resp) => resp.data,
    )
  },
)

export const selectSettings = (state: RootState) => state.settings
export const selectActiveShops = (state: RootState) =>
  state.settings.activeShops

export default settingsSlice.reducer

export const { setSettingsState, setPriceParams } = settingsSlice.actions
