import "./App.css"
import { Auth } from "./auth/Auth"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { Home } from "./components/Home/Home"
import { FC, useEffect } from "react"
import { NotificationService } from "./features/NotificationService/NotificationService"
import { useAppDispatch } from "./app/hooks"
import { SysApi } from "./api/sysapi"
import { WSocketService } from "./app/services/ws/WSocketService"

const App: FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    WSocketService.connect(SysApi.baseUrl)
    return () => {
      WSocketService.disconnect()
    }
  }, [dispatch])

  const token = localStorage.getItem("token")
  if (!token && location.pathname !== "/login") {
    location.replace("login")
    throw new Error("Пользователь не авторизирован")
  }

  return (
    <main className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/:activeTab?/:activeSubTab?" element={<Home />} />
          <Route path="login" element={<Auth />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
      <div id={"loader"}></div>
      <NotificationService />
    </main>
  )
}

export default App
