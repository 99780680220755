import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import authReducer from "./reducers/authReducer"
import infoSlice from "./reducers/infoSlice"
// import UploadSlice from "./reducers/generalKtrn"
// import UpdateStoks from "./reducers/updateStoks"
import ordersReducer from "./reducers/ordersReducer"
import updateStoksIconReducer from "./reducers/updateStoksIcon"
import updateStoksKatrenReducer from "./reducers/updateStoksKatren"
import settingsReducer from "./reducers/settingsReducer"
import updateStoksPodruzhkaReducer from "./reducers/updateStoksPodruzhka"
import updateStoksMetroReducer from "./reducers/updateStoksMetro"
// import WSocketService from "./reducers/webSocketReducer"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    info: infoSlice,
    // generalKtrn: UploadSlice,
    // updateStoksStore: UpdateStoks,
    ordersStore: ordersReducer,
    updateStoksIcon: updateStoksIconReducer,
    settings: settingsReducer,
    updateStoksKatren: updateStoksKatrenReducer,
    updateStoksPodruzhka: updateStoksPodruzhkaReducer,
    updateStoksMetro: updateStoksMetroReducer,
    // WSocketService: WSocketService,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
