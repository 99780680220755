import { Tabs } from "../Tabs/Tabs"
import { TabContent } from "../Tab/TabContent"
import { NewOrders } from "../NewOrders/NewOrders"
import { Supplies } from "../Supplies/Supplies"
import { UpdateStoksIcon } from "../UpdateStoksIcon/UpdateStoksIcon"
import { Settings } from "../Settings/Settings"
import { UpdateStoksKatren } from "../UpdateStoksKatren/UpdateStoksKatren"
import { FC, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { UpdateStoksPodruzhka } from "../UpdateStoksPodrugka/UpdateStoksPodruzhka"
import {
  selectActiveShops,
  updateSettingsState,
} from "../../app/reducers/settingsReducer"
import { UpdateStoksMetro } from "../UpdateStoksMetro/UpdateStoksMetro"
import { getOrdersState } from "../../app/reducers/ordersReducer"
import { useNavigate, useParams } from "react-router-dom"
import { subscribeChangedThunk } from "../../app/services/ws/subscribeThunk"

export const Home: FC = () => {
  const { activeTab } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getOrdersState())
    dispatch(updateSettingsState())
    dispatch(subscribeChangedThunk())
  }, [dispatch])

  const activeShops = useAppSelector(selectActiveShops)
  const [katrenShop, setKatrenShop] = useState<string>()
  const shops = (activeShops || [])
    .map((shopName) => {
      switch (shopName) {
        case "KATREN":
        case "KATREN_OZON":
          if (!katrenShop) setKatrenShop(shopName)
          if (katrenShop !== shopName) return null
          return (
            <TabContent name={"Обновить остатки Katren"} ident={"katren"}>
              <UpdateStoksKatren />
            </TabContent>
          )
        case "ICON":
          return (
            <TabContent name={"Обновить остатки ICON"} ident={"icon"}>
              <UpdateStoksIcon />
            </TabContent>
          )
        case "PODRUZHKA":
          return (
            <TabContent name={"Обновить остатки Подружка"} ident={"podruzhka"}>
              <UpdateStoksPodruzhka />
            </TabContent>
          )
        case "METRO":
          return (
            <TabContent name={"Обновить остатки Metro"} ident={"metro"}>
              <UpdateStoksMetro />
            </TabContent>
          )
        default:
          return null
      }
    })
    .filter(Boolean)

  return (
    <div className="homePage">
      <div className="SHOP_NAME">ИП ЧУПОВА</div>
      <Tabs
        activeTabIdent={activeTab}
        onChange={(ident) => navigate(`/${ident}`)}
      >
        {/* <TabContent name={"Поиск по артикулам"}>
          <Info />
        </TabContent> */}
        {/* <TabContent name={"Загрузка карточек"}>
          <Upload />
        </TabContent> 
        <TabContent name={"Обновить остатки Katren Old"}>
          <UpdateStoksOld />
        </TabContent>
        */}
        {...shops}
        <TabContent name={"Сборочные задания"} ident={"orders"}>
          <NewOrders />
        </TabContent>
        <TabContent name={"Поставки"} ident={"supplies"}>
          <Supplies />
        </TabContent>
        <TabContent name={"Настройки"} ident={"settings"}>
          <Settings />
        </TabContent>
      </Tabs>
    </div>
  )
}
