import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import BaseApi from "../../api/api"
import { NotificationService } from "../../features/NotificationService/NotificationService"
import { STATUS } from "../../utils/const"

type initialStateType = {
  lastStoksUpdated: string
  lastPricesUpdated: string
  status?: STATUS
  isCurrentDataUploaded: boolean

  ozon?: {
    lastStoksUpdated: string
    lastPricesUpdated: string
    status?: STATUS
    isCurrentDataUploaded: boolean
  }

  isDataFromKatrenUploaded: boolean
}

const initialState: initialStateType = {
  lastStoksUpdated: "",
  lastPricesUpdated: "",
  isCurrentDataUploaded: false,
  isDataFromKatrenUploaded: false,
  ozon: {
    lastStoksUpdated: "",
    lastPricesUpdated: "",
    isCurrentDataUploaded: false,
  },
}

const updateStoksKatrenSlice = createSlice({
  name: "updateStoksKatren",
  initialState,
  reducers: {
    setUpdateStoksKatrenState: (
      state,
      action: PayloadAction<initialStateType>,
    ) => {
      state.lastStoksUpdated = action.payload.lastStoksUpdated
      state.lastPricesUpdated = action.payload.lastPricesUpdated
      state.status = action.payload.status
      state.isCurrentDataUploaded = action.payload.isCurrentDataUploaded
      state.isDataFromKatrenUploaded = action.payload.isDataFromKatrenUploaded
      state.ozon = action.payload.ozon
    },
  },
})

export const selectUpdateStoksKatrenStore = (state: RootState) =>
  state.updateStoksKatren

export default updateStoksKatrenSlice.reducer

export const { setUpdateStoksKatrenState } = updateStoksKatrenSlice.actions

export const deleteCurrentDataKatrenForOzon = createAsyncThunk(
  "deleteCurrentDataKatrenForOzon",
  (_, { dispatch }) => {
    BaseApi.deleteCurrentDataKatrenForOzon()
      .then((response) => {
        console.log(response.data)
        response.data?.state &&
          dispatch(setUpdateStoksKatrenState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
        // Обработайте ошибку загрузки файла
      })
  },
)

export const uploadCurrentDataKatrenForOzon = createAsyncThunk(
  "uploadCurrentDataKatrenForOzon",
  (file: File, { dispatch }) => {
    const formData = new FormData()
    formData.append("file", file)
    BaseApi.uploadCurrentDataKatrenForOzon(formData)
      .then((response) => {
        response.data?.state &&
          dispatch(setUpdateStoksKatrenState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
        // Обработайте ошибку загрузки файла
      })
  },
)

export const katrenUpdateStoksForOzon = createAsyncThunk(
  "katrenUpdateStoksForOzon",
  (
    props: {
      selectedStorageId: string
      updatePrices: boolean
      updateStocks: boolean
    },
    { dispatch },
  ) => {
    const { selectedStorageId, updatePrices, updateStocks } = props
    BaseApi.katrenUpdateStoksForOzon(
      selectedStorageId,
      updatePrices,
      updateStocks,
    )
      .then((response) => {
        if (response.data) {
          response.data?.state &&
            dispatch(setUpdateStoksKatrenState(response.data.state))
          NotificationService.showNotification({
            message: response.data.message,
            level: (response.data.success && "success") || "error",
          })
        }
      })
      .catch((error) => {
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
      })
  },
)

export const uploadDataFromKatren = createAsyncThunk(
  "uploadDataFromKatren",
  (data: { name: string; amount: number; price: number }[], { dispatch }) => {
    BaseApi.uploadDataFromKatren(data)
      .then((response) => {
        response.data?.state &&
          dispatch(setUpdateStoksKatrenState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
        // Обработайте ошибку загрузки файла
      })
  },
)

export const deleteDataFromKatren = createAsyncThunk(
  "deleteDataFromKatren",
  (_, { dispatch }) => {
    BaseApi.deleteDataFromKatren()
      .then((response) => {
        console.log(response.data)
        response.data?.state &&
          dispatch(setUpdateStoksKatrenState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
        // Обработайте ошибку загрузки файла
      })
  },
)

export const updateKatrenStoksState = createAsyncThunk(
  "updateKatrenStoksState",
  (_, { dispatch }) => {
    BaseApi.katrenUpdateStoksState()
      .then((response) => {
        if (response.data) {
          dispatch(setUpdateStoksKatrenState(response.data))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  },
)

export const deleteCurrentDataKatrenForWB = createAsyncThunk(
  "deleteCurrentDataKatrenForWB",
  (_, { dispatch }) => {
    BaseApi.deleteCurrentDataKatren()
      .then((response) => {
        console.log(response.data)
        response.data?.state &&
          dispatch(setUpdateStoksKatrenState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
        // Обработайте ошибку загрузки файла
      })
  },
)

export const uploadCurrentDataKatrenForWB = createAsyncThunk(
  "uploadCurrentDataKatrenForWB",
  (file: File, { dispatch }) => {
    const formData = new FormData()
    formData.append("file", file)
    BaseApi.uploadCurrentDataKatren(formData)
      .then((response) => {
        response.data?.state &&
          dispatch(setUpdateStoksKatrenState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
        // Обработайте ошибку загрузки файла
      })
  },
)

export const katrenUpdateStoksForWB = createAsyncThunk(
  "katrenUpdateStoksForWB",
  (
    props: {
      selectedStorageId: string
      updatePrices: boolean
      updateStocks: boolean
    },
    { dispatch },
  ) => {
    const { selectedStorageId, updatePrices, updateStocks } = props
    BaseApi.katrenUpdateStoks(selectedStorageId, updatePrices, updateStocks)
      .then((response) => {
        if (response.data) {
          response.data?.state &&
            dispatch(setUpdateStoksKatrenState(response.data.state))
          NotificationService.showNotification({
            message: response.data.message,
            level: (response.data.success && "success") || "error",
          })
        }
      })
      .catch((error) => {
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
      })
  },
)
