import { FC, useEffect, useState } from "react"
import s from "../NewOrders.module.css"
import {
  IOrder,
  ISupply,
  addOrdersToSupplies,
  createNewSuppliesAndAddOrders,
  setNewOrders,
  updateSupplies,
} from "../../../app/reducers/ordersReducer"
import { useAppDispatch } from "../../../app/hooks"
import { Modal } from "../../../features/Modal/Modal"
import { CreateNewSupplies } from "./CreateNewSupplies"
import { AddToSupplies } from "./AddToSupplies"

type WBNewOrdersProps = {
  orders: IOrder[]
  lastDateUpdatedSupplies?: string
  storages: {
    name: string
    officeId: number
    id: number
  }[]
  supplies: ISupply[]
}

export const WBNewOrders: FC<WBNewOrdersProps> = ({
  orders,
  storages,
  supplies,
  lastDateUpdatedSupplies,
}) => {
  const dispatch = useAppDispatch()
  const [selected, setSelected] = useState<IOrder[]>([])
  const [isOpenModalCreateSupplies, setOpenModalCreateSupplies] = useState(
    false,
  )
  const [isOpenModalAddToSupplies, setOpenModalAddToSupplies] = useState(false)
  const [selectedStorageId, setSelectedStorages] = useState("")
  const [selectedSupplyId, setSelectedSupply] = useState("")

  let newSupplyName = `${
    storages.find((el) => +el.id === +selectedStorageId)?.name
  }-${new Date().toLocaleString()}`?.replace(", ", "-")
  return (
    <div>
      <div className={s.orderContent}>
        <div className={s.table}>
          <div className={s.dropdown}>
            <label htmlFor="storages-select">Отфильтровать по складу</label>
            <select
              name="stock"
              id="storages-select"
              value={selectedStorageId}
              onChange={(e) => {
                setSelected([])
                setSelectedStorages(e.target.value)
              }}
            >
              <option value={""}>Выбрать склад</option>
              {storages.map((storage) => (
                <option value={storage.id} key={storage.name}>
                  {storage.name}
                </option>
              ))}
            </select>
          </div>
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      e.target.checked ? setSelected(orders) : setSelected([])
                    }}
                    checked={selected?.length === orders.length}
                  />
                </th>
                <th>№ задания</th>
                <th>Создано</th>
                <th>Склад</th>
                <th>Артикул продавца</th>
                <th>Артикул WB</th>
                <th>Цена</th>
              </tr>
            </thead>
            <tbody>
              {orders
                .filter((ord) =>
                  selectedStorageId
                    ? +ord.warehouseId === +selectedStorageId
                    : true,
                )
                .map((el, i) => {
                  const isSelected = selected?.find(
                    (sel: any) => el.id === sel?.id,
                  )
                  return (
                    <tr
                      className={`${s.rowValue} ${
                        selected?.find((sel: any) => el.id === sel?.id)
                          ? s.active
                          : ""
                      }`}
                      key={el.article + "-" + i}
                      onClick={(e: any) => {
                        !e.target.classList.contains(s.checkbox) &&
                          setSelected([el])
                      }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          className={s.checkbox}
                          onChange={(e) => {
                            e.target.checked
                              ? setSelected([...selected, el])
                              : setSelected(
                                  selected.filter(
                                    (sel: any) => sel.id !== el.id,
                                  ),
                                )
                          }}
                          checked={!!isSelected}
                        />
                      </td>
                      <td>{el.id}</td>
                      <td>{new Date(el.createdAt).toLocaleString()}</td>
                      <td>{el.warehouseId}</td>
                      <td>{el.article}</td>
                      <td>{el.nmId}</td>
                      <td>{el.convertedPrice / 100}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className={s.buttons}>
          <button onClick={() => dispatch(setNewOrders())}>
            Обновить список заказов
          </button>
          <button
            disabled={!selected.length || !selectedStorageId}
            onClick={() => {
              if (selected.length) {
                setOpenModalCreateSupplies(true)
              }
            }}
          >
            Создать новую поставку и добавить в нее выбранные задания
          </button>
          <button
            disabled={!selected.length}
            onClick={() => setOpenModalAddToSupplies(true)}
          >
            Добавить задания в поставку
          </button>
        </div>
      </div>
      <Modal
        isOpen={isOpenModalCreateSupplies}
        onClose={() => {
          setOpenModalCreateSupplies(false)
        }}
        header={"Создать новую поставку и добавить в нее выбранные задания"}
        onOk={() => {
          newSupplyName &&
            selectedStorageId &&
            orders.length &&
            dispatch(
              createNewSuppliesAndAddOrders({
                name: newSupplyName,
                orderIds: orders.map((el) => el.id),
              }),
            )
        }}
        content={
          <CreateNewSupplies orders={selected} newSupplyName={newSupplyName} />
        }
        size="auto"
      />
      <Modal
        isOpen={isOpenModalAddToSupplies}
        onClose={() => {
          setOpenModalAddToSupplies(false)
        }}
        header={"Добавить выбранные задания в существующую поставку"}
        onOk={() => {
          orders.length &&
            selectedSupplyId &&
            dispatch(
              addOrdersToSupplies({
                supplyId: +selectedSupplyId,
                orderIds: orders.map((el) => el.id),
              }),
            )
        }}
        content={
          <AddToSupplies
            orders={selected}
            supplies={supplies}
            lastDateUpdatedSupplies={lastDateUpdatedSupplies}
            updateSupplies={() => dispatch(updateSupplies())}
            selectedSupply={selectedSupplyId}
            setSelectedSupply={setSelectedSupply}
          />
        }
        size="auto"
        isOkDisabled={!selectedSupplyId}
      />
    </div>
  )
}
