import { ChangeEvent, FC, useState } from "react"
import { Tip, TipType } from "../Tip/Tip"
import s from "./UpdateStoks.module.css"
import JSZip from "jszip"
import { Modal } from "../../features/Modal/Modal"
import { prepareXLSX, prepareZip } from "./helpers"

type UploadMultipleFilesFromKatrenProps = {
  deleteDataFromKatren: () => void
  isDataFromKatrenUploaded: boolean
  uploadDataFromKatren: (
    data: { name: string; amount: number; price: number }[],
  ) => void
}

export const UploadMultipleFilesFromKatren: FC<UploadMultipleFilesFromKatrenProps> = ({
  deleteDataFromKatren,
  isDataFromKatrenUploaded,
  uploadDataFromKatren,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [files, setFiles] = useState<{ [key: string]: JSZip.JSZipObject }>({})
  const [mainFileName, setMainFileName] = useState("")
  return (
    <div className={s.uploadFileWrap}>
      {isDataFromKatrenUploaded ? (
        <div>
          <button onClick={deleteDataFromKatren}>
            удалить файл цен и остатков с Катрена
          </button>
        </div>
      ) : (
        <div>
          <label className={s.ktrnFileLabel} htmlFor={s.pricesFromKtrnInp}>
            Загрузить zip-файл цен и остатков с Катрена
          </label>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.target.files &&
                prepareZip(e.target.files[0]).then((files) => {
                  setFiles(files)
                  setOpen(true)
                  // uploadDataFromKatren(data)
                })
            }}
            type="file"
            accept=".zip"
            id={s.pricesFromKtrnInp}
          />
        </div>
      )}
      <Tip
        content={
          <span>
            <b>Внимание!</b>
            <p>
              Прикрепляемый файл должен быть в формате <b>ZIP</b>. В архиве
              должны быть файлы цен и остатков в формате <b>XLSX</b>. XLSX-файлы
              должны содержать следующие <b>обязательные</b> колонки:
            </p>
            <ol className={s.list}>
              <li>
                <b>Название</b> - название продукта
              </li>
              <li>
                <b>Остаток</b> - остаток на складе катрена
              </li>
              <li>
                <b>Цена за 1 уп.</b> - цена
              </li>
            </ol>
          </span>
        }
        type={TipType.WARNING}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setOpen(false)
          setFiles({})
          setMainFileName("")
        }}
        onOk={() => {
          prepareXLSX(files, mainFileName).then(uploadDataFromKatren)
        }}
        isOkDisabled={!mainFileName}
        header="Выберите файл из которого загрузить остатки"
        content={
          <div className={s.radioWrap}>
            {Object.keys(files).map((key) => (
              <div key={key}>
                <input
                  type="radio"
                  id={key}
                  name={key}
                  value={key}
                  onChange={() => {
                    setMainFileName(key)
                  }}
                  checked={key === mainFileName}
                />
                <label htmlFor={key}> {key}</label>
              </div>
            ))}
          </div>
        }
      ></Modal>
    </div>
  )
}
