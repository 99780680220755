import { FC, useEffect, useState } from "react"
import s from "./UpdateStoksIcon.module.css"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Modal } from "../../features/Modal/Modal"
import {
  selectUpdateStoksIconStore,
  updatePricesICON,
  updateStoksICONState,
} from "../../app/reducers/updateStoksIcon"
import { STATUS } from "../../utils/const"
import { StatusInfo } from "./StatusInfo/StatusInfo"

export const UpdateStoksIcon: FC = () => {
  const dispatch = useAppDispatch()
  const [isOpenModalUpdateStoks, setOpenModalUpdateStoks] = useState(false)
  const [cookie, setCookie] = useState("")
  useEffect(() => {
    dispatch(updateStoksICONState())
  }, [])
  const updateStoksStore = useAppSelector(selectUpdateStoksIconStore)

  const { status, lastUpdated } = updateStoksStore
  return (
    <div className={s.updateStoksWrapper}>
      <h5>Загрузка остатков и обновление цен</h5>
      <h6>
        Последнее обновление:{" "}
        {lastUpdated && new Date(lastUpdated).toLocaleString()}
      </h6>
      <div>
        <div className={s.content}>
          <div className={s.cookie}>
            <h6>вставьте куки:</h6>
            <textarea
              value={cookie}
              onChange={(e) => setCookie(e.target.value)}
            ></textarea>
          </div>
          <div>
            <h6>статус:</h6>
            {status && (
              <div
                className={`${s.status} ${
                  status &&
                  [STATUS.INVALID_COOKIES, STATUS.ERROR].includes(status) &&
                  s.error
                }`}
              >
                <StatusInfo status={status} />
              </div>
            )}
          </div>
          <button
            className={s.btn}
            disabled={!cookie}
            onClick={() => setOpenModalUpdateStoks(true)}
          >
            Обновить цены и остатки
          </button>
        </div>
      </div>
      <Modal
        isOpen={isOpenModalUpdateStoks}
        header="Подтвердите действие"
        content={"Обновить остатки и цены"}
        onClose={() => setOpenModalUpdateStoks(false)}
        onOk={() => {
          dispatch(updatePricesICON(cookie))
        }}
      />
    </div>
  )
}
