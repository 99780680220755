import { FC, useState } from "react"
import {
  IOzonOrder,
  setOzonNewOrders,
} from "../../../app/reducers/ordersReducer"
import s from "../NewOrders.module.css"
import { useAppDispatch } from "../../../app/hooks"

export const OzonNewOrders: FC<{
  orders: IOzonOrder[]
  storages: {
    name: string
    warehouse_id: number
  }[]
}> = ({ orders, storages }) => {
  const [selectedStorageId, setSelectedStorages] = useState("")
  const dispatch = useAppDispatch()
  return (
    <div>
      <div className={s.orderContent}>
        <div className={s.table}>
          <div className={s.dropdown}>
            <label htmlFor="ozon-storages-select">
              Отфильтровать по складу
            </label>
            <select
              name="stock"
              id="ozon-storages-select"
              value={selectedStorageId}
              onChange={(e) => {
                setSelectedStorages(e.target.value)
              }}
            >
              <option value={""}>Выбрать склад</option>
              {storages.map((storage) => (
                <option value={storage.warehouse_id} key={storage.name}>
                  {storage.name}
                </option>
              ))}
            </select>
          </div>
          <table>
            <thead>
              <tr>
                <th>Номер отправления.</th>
                <th>Список товаров в отправлении.</th>
              </tr>
            </thead>
            <tbody>
              {orders
                .filter((ord) =>
                  selectedStorageId
                    ? +ord.warehouseId === +selectedStorageId
                    : true,
                )
                .map((el, i) => {
                  return (
                    <tr
                      className={`${s.rowValue}`}
                      key={el.postingNumber + "-" + i}
                    >
                      <td>{el.postingNumber}</td>
                      <td>
                        {el.products.map((p) => (
                          <p>
                            {p.offer_id} {p.price} {p.currency_code}
                          </p>
                        ))}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className={s.buttons}>
          <button onClick={() => dispatch(setOzonNewOrders())}>
            Обновить список заказов
          </button>
        </div>
      </div>
    </div>
  )
}
