import { FC } from "react"
import { IOrder, ISupply } from "../../../app/reducers/ordersReducer"
import s from "../NewOrders.module.css"

type CreateNewSuppliesProps = {
  orders: IOrder[]
  supplies: ISupply[]
  lastDateUpdatedSupplies?: string
  updateSupplies: () => void
  selectedSupply: string
  setSelectedSupply: (supp: string) => void
}

export const AddToSupplies: FC<CreateNewSuppliesProps> = ({
  orders,
  supplies,
  lastDateUpdatedSupplies,
  updateSupplies,
  selectedSupply,
  setSelectedSupply,
}) => {
  return (
    <div>
      <div>
        <div>
          <div>
            <select
              name="stock"
              id="pet-select"
              value={selectedSupply}
              onChange={(e) => {
                setSelectedSupply(e.target.value)
              }}
            >
              <option value={""}>Выбрать поставку</option>
              {supplies.map((storage) => (
                <option value={storage.id} key={storage.name}>
                  {storage.name}
                </option>
              ))}
            </select>
            <button className={s.updateSupplies} onClick={updateSupplies}>
              Обновить поставки
              {lastDateUpdatedSupplies &&
                ` (обновлено: ${lastDateUpdatedSupplies})`}
            </button>
          </div>
        </div>
        <div>
          <h5>Выбранные позиции</h5>
          <table>
            <thead>
              <tr>
                <th>№ задания</th>
                <th>Создано</th>
                <th>Склад</th>
                <th>Артикул продавца</th>
                <th>Артикул WB</th>
                <th>Цена</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((el, i) => {
                return (
                  <tr key={el.article + "-add-" + i}>
                    <td>{el.id}</td>
                    <td>{new Date(el.createdAt).toLocaleString()}</td>
                    <td>{el.warehouseId}</td>
                    <td>{el.article}</td>
                    <td>{el.nmId}</td>
                    <td>{el.convertedPrice / 100}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
