import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import BaseApi from "../../api/api"
import { NotificationService } from "../../features/NotificationService/NotificationService"
import { STATUS } from "../../utils/const"

type initialStateType = {
  lastUpdated: string
  status?: STATUS
}

const initialState: initialStateType = {
  lastUpdated: "",
}

const updateStoksIconSlice = createSlice({
  name: "updateStoksIcon",
  initialState,
  reducers: {
    setUpdateStoksIconState: (
      state,
      action: PayloadAction<{
        lastUpdated: string
        status: STATUS
      }>,
    ) => {
      state.lastUpdated = action.payload.lastUpdated
      state.status = action.payload.status
    },
    setStatus: (state, action: PayloadAction<STATUS>) => {
      state.lastUpdated = action.payload
    },
  },
})

export const selectUpdateStoksIconStore = (state: RootState) =>
  state.updateStoksIcon

export default updateStoksIconSlice.reducer

export const {
  setUpdateStoksIconState,
  setStatus,
} = updateStoksIconSlice.actions

export const updateStoksICONState = createAsyncThunk(
  "updateStoksICONState",
  (_, { dispatch }) => {
    BaseApi.updateStoksICONState()
      .then((response) => {
        if (response.data) {
          dispatch(setUpdateStoksIconState(response.data))
        }
      })
      .catch((error) => {
        console.error(error)
        dispatch(
          setUpdateStoksIconState({
            lastUpdated: "",
            status: STATUS.ERROR,
          }),
        )
      })
  },
)

export const updatePricesICON = createAsyncThunk(
  "updatePricesICON",
  (cookie: string, { dispatch }) => {
    BaseApi.updatePricesICON(cookie)
      .then((response) => {
        dispatch(updateStoksICONState())
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        dispatch(updateStoksICONState())
        // Обработайте ошибку загрузки файла
      })
  },
)
